import React from 'react';
import { func, array, object } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl.js';
import { Form } from 'react-final-form';
import { PrimaryButton } from '../../components/Button/Button.js';
import { FieldLocationAutocompleteInput } from '../LocationAutocompleteInput/LocationAutocompleteInput';
import FieldDateRangeInput from '../FieldDateRangeController/FieldDateRangeController';
import FilterPopupForSidebar from '../../containers/SearchPage/BookingDateRangeFilter/FilterPopupForSidebar.js';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import { filterListings } from '../../util/locationUtils.js';
import DateRangeInputWrapper from '../DateRangeInputWrapper/DateRangeInputWrapper';

import css from './AdditionalFilters.module.css';

const AdditionalFilters = ({
  onApplyFilters,
  listings,
  intl,
  listingFieldsConfig,
  defaultFiltersConfig,
}) => {

  const handleApplyFilters = async values => {
    const { location, dates, guests } = values;
    try {
      const filteredListings = await filterListings(location, dates, listings);
      onApplyFilters({ 
        filteredListings, 
        listingFieldsConfig, 
        defaultFiltersConfig, 
        appliedFilters: values // Pass the filter values up
      });
    } catch (error) {
      console.error('Error filtering listings:', error);
    }
  };

  return (
    <div className={css.filtersContainer}>
      <p className={css.introText}>
        <FormattedMessage
          id="AdditionalFilters.introText"
          defaultMessage="For at g�re din s�gning mere pr�cis, bedes du indtaste flere oplysninger p� dit event."
        />
      </p>
      <Form
        onSubmit={handleApplyFilters}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={css.filterWrapper}>
            <div className={css.filter}>
              <FieldLocationAutocompleteInput
                name="location"
                placeholder={intl.formatMessage({
                  id: 'AdditionalFilters.eventLocationPlaceholder',
                })}
                label={intl.formatMessage({
                  id: 'AdditionalFilters.eventLocation',
                })}
              />
            </div>
            <div className={css.filter}>
              <DateRangeInputWrapper
                name="dates"
                id="dates"
                label={intl.formatMessage({
                  id: 'AdditionalFilters.eventDate',
                })}
              />
            </div>
            <div className={css.filter}>
              <FieldTextInput
                className={css.bookingDatesBudget}
                name="guests"
                type="number"
                id="guests"
                label={intl.formatMessage({
                  id: 'AdditionalFilters.eventGuests',
                })}
              />
            </div>
            <PrimaryButton className={css.applyButton} type="submit">
              <FormattedMessage id="AdditionalFilters.eventApplyFilter" />
            </PrimaryButton>
          </form>
        )}
      />
    </div>
  );
};

AdditionalFilters.propTypes = {
  onApplyFilters: func.isRequired,
  listings: array.isRequired,
  intl: intlShape.isRequired,
  listingFieldsConfig: object.isRequired,
  defaultFiltersConfig: object.isRequired,
};

export default injectIntl(AdditionalFilters);
