import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Field } from 'react-final-form';
import classNames from 'classnames';
import IconAdd from '../IconAdd/IconAdd';
import FieldDateRangeInput from '../FieldDateRangeController/FieldDateRangeController';
import css from './DateRangeInputWrapper.module.css';

const DateRangeInputWrapperComponent = props => {
  const { input, meta, label, id, placeholder, className, rootClassName } = props;

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleFocus = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    let formattedDate = '';
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      formattedDate =
        formattedStartDate === formattedEndDate
          ? formattedStartDate
          : `${formattedStartDate} - ${formattedEndDate}`;
    } else if (startDate) {
      formattedDate = formatDate(startDate);
    }

    input.onChange(formattedDate); // Pass the formatted string to input.onChange
    setIsOpen(false); // Close the picker
  };

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
  }, [isOpen]);

  useEffect(() => {
  }, [input.value]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()); // Get last two digits of year
    return `${day}-${month}-${year}`;
  };

  const formatDates = (inputValue) => {
    if (inputValue && inputValue.startDate && inputValue.endDate) {
      const formattedStartDate = formatDate(new Date(inputValue.startDate));
      const formattedEndDate = formatDate(new Date(inputValue.endDate));
      return formattedStartDate === formattedEndDate
        ? formattedStartDate
        : `${formattedStartDate} - ${formattedEndDate}`;
    }
    return '';
  };

  return (
    <div ref={wrapperRef} className={classNames(rootClassName || css.root, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className={css.inputWrapper}>
        <input
          id={id}
          className={css.input}
          value={formatDates(input.value)} // Call formatDates with the input value
          placeholder={input.value ? '' : placeholder}
          readOnly
          onClick={handleFocus}
        />
      </div>
      {isOpen && (
        <div className={css.dateRangePicker}>
          <FieldDateRangeInput
            name={input.name}
            isDaily={false}
            onDatesChange={handleDateChange}
            focusedInput={meta.active ? 'startDate' : null}
          />
        </div>
      )}
    </div>
  );
};

DateRangeInputWrapperComponent.defaultProps = {
  className: null,
  rootClassName: null,
  placeholder: 'Select date range',
};

DateRangeInputWrapperComponent.propTypes = {
  className: PropTypes.string, // Corrected PropTypes usage
  rootClassName: PropTypes.string, // Corrected PropTypes usage
  input: PropTypes.object.isRequired, // Corrected PropTypes usage
  meta: PropTypes.object.isRequired, // Corrected PropTypes usage
  label: PropTypes.string, // Corrected PropTypes usage
  id: PropTypes.string.isRequired, // Corrected PropTypes usage
  placeholder: PropTypes.string, // Corrected PropTypes usage
};

const DateRangeInputWrapper = props => {
  return <Field component={DateRangeInputWrapperComponent} {...props} />;
};

export default DateRangeInputWrapper;
