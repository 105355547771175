import React, { Component } from 'react';
import { string } from 'prop-types';
import { DayPickerRangeController } from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';
import { START_DATE } from '../../util/dates';

import { IconArrowHead } from '../../components';
import css from './DateRangeController.module.css';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const defaultProps = {
  startDateOffset: undefined,
  endDateOffset: undefined,
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  isRTL: false,
  initialVisibleMonth: null,
  firstDayOfWeek: 0,
  numberOfMonths: 1,
  daySize: 38,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200,
  renderDayContents: day => <span className="renderedDay">{day.format('D')}</span>,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => false,
  isDayHighlighted: () => {},
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
};

class DateRangeController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.value && props.value.startDate ? moment(props.value.startDate) : null,
      endDate: props.value && props.value.endDate ? moment(props.value.endDate) : null,
      focusedInput: START_DATE,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  onDatesChange(values) {
    const { startDate, endDate } = values;
  
    // Format the dates to a readable string format
    const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : 'No start date selected';
    const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : 'No end date selected';
  
    // Log the formatted dates
 
    const start = startDate ? startDate.toDate() : null;
    const end = endDate ? endDate.toDate() : null;
  
    this.setState({ startDate, endDate });
  
    if (startDate && endDate) {
      this.props.onChange({ startDate: start, endDate: end });
    }
  }
  
  

  onFocusChange(focusedInput) {
    this.setState({
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  onReset(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({
        startDate: moment(startDate),
        endDate: moment(endDate),
        focusedInput: START_DATE,
      });
    } else {
      this.setState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      name,
      value,
      onChange,
      onFocus,
      meta,
      children,
      render,
      ...controllerProps
    } = this.props;

    const classes = classNames(rootClassName || css.inputRoot, className);

    const startDateFromState = this.state.startDate;
    const endDateFromState = this.state.endDate;

    const startDateFromForm = value && value.startDate ? moment(value.startDate) : null;
    const endDateFromForm = value && value.endDate ? moment(value.endDate) : null;

    const isSelected = startDateFromState && endDateFromState;

    const startDate = isSelected ? startDateFromForm : startDateFromState;
    const endDate = isSelected ? endDateFromForm : endDateFromState;

    return (
      <div className={classes}>
        <DayPickerRangeController
          {...controllerProps}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.onDatesChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.onFocusChange}
        />
      </div>
    );
  }
}

DateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
  ...defaultProps,
};

DateRangeController.propTypes = {
  rootClassName: string,
  className: string,
};

export default DateRangeController;
