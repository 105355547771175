// src/utils/locationUtils.js

import axios from 'axios';

const GEOCODING_API_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const DISTANCE_MATRIX_API_URL = 'https://api.mapbox.com/directions-matrix/v1/mapbox/driving';
const API_KEY =
  'pk.eyJ1IjoiZ3VzdGF2a3Jvd2lja2kiLCJhIjoiY2t5cm1jN3I4MHY5dTMxbW0xYzk4eHU4MiJ9.q9Icq40OWrjrhIB64k2h3A';
export const geocodeAddress = async address => {
  try {
    const response = await axios.get(`${GEOCODING_API_URL}/${encodeURIComponent(address)}.json`, {
      params: {
        access_token: API_KEY,
      },
    });

    // Check if response contains any features
    if (response.data.features.length > 0) {
      // Optionally, log all returned locations for debugging
      response.data.features.forEach((feature, index) => {
      });

      // Optionally, implement selection logic to choose the best match
      const bestMatch = response.data.features[0];
      const [lng, lat] = bestMatch.geometry.coordinates;
      return { lat, lng };
    }

    throw new Error('Geocoding failed: No features found');
  } catch (error) {
    console.error('Geocoding error:', error);
    throw error;
  }
};

export const getDrivingDistance = async (userLatLng, truckLocations) => {
  try {
    const truckLocationsString = truckLocations.map(d => `${d.lng},${d.lat}`).join(';');
    const response = await axios.get(
      `${DISTANCE_MATRIX_API_URL}/${userLatLng.lng},${userLatLng.lat};${truckLocationsString}`,
      {
        params: {
          access_token: API_KEY,
          annotations: 'distance',
        },
      }
    );

    if (response.data && response.data.distances) {
      const distancesArray = response.data.distances[0].slice(1).map(distance => distance / 1000); // Convert to kilometers
      if (distancesArray.length) {
        return distancesArray;
      } else {
        throw new Error('No distances found in API response');
      }
    } else {
      throw new Error('Invalid API response structure');
    }
  } catch (error) {
    console.error('Distance Matrix error:', error);
    throw error;
  }
};

export const filterListings = async (location, dates, listings) => {
  try {
    // Collect user latitude and longitude into an object
    const userLocation = {
      lat: location.selectedPlace.origin.lat,
      lng: location.selectedPlace.origin.lng,
    };

    const truckLocations = listings.map(listing => ({
      lat: listing.attributes.geolocation.lat,
      lng: listing.attributes.geolocation.lng,
    }));
    const distances = await getDrivingDistance(userLocation, truckLocations);

    const filteredListings = listings.filter((listing, index) => {
      const buildingDistance = parseFloat(listing.attributes.publicData.location.building);
      const isWithinDistance = buildingDistance >= distances[index];
      const isAvailableOnDate = listing.unavailableDates
        ? !listing.unavailableDates.includes(dates)
        : true;

      return isWithinDistance && isAvailableOnDate;
    });

    return filteredListings;
  } catch (error) {
    console.error('Error filtering listings:', error);
    throw error;
  }
};
